import { CustomDialogRef } from 'components/CustomDialog/types';
import { StatusCodes } from 'http-status-codes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import OrderService from 'services/orderService';
import { Order } from 'types/order';
import { Payment } from 'types/payment';
import { useServiceClassFull } from 'hooks/useServiceClassFull';
import { MarketplaceContractsRouteParams } from './types';
import { ServiceClass } from '../MarketplaceServicesDetail/types';

export const useMarketplaceContractsController = () => {
  // Navigation
  const history = useHistory();
  const {
    params: { orderId },
  } = useRouteMatch<MarketplaceContractsRouteParams>();

  // State
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const modalSuccessRef = React.useRef<CustomDialogRef>(null);
  const modalErrorRef = React.useRef<CustomDialogRef>(null);
  const [orderDetails, setOrderDetails] = useState<Order>();
  const [checkboxesByPaymentId, setCheckboxesByPaymentId] = useState<{
    [key: string]: boolean;
  }>({});
  const [serviceClassFull, setServiceClassFull] = useState<ServiceClass[]>();
  const { getServiceClassFull } = useServiceClassFull();

  const fetchMarketplaceOrderData = useCallback(async () => {
    if (!orderId) return;

    try {
      const { data, status } = await OrderService.getOrderContracts(orderId);

      if (status !== StatusCodes.OK) {
        throw new Error(data.message);
      }

      setOrderDetails(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [orderId]);

  useEffect(() => {
    const paymentIds = (orderDetails?.payments || [])?.map(
      (payment: Payment) => payment.id,
    );

    if (paymentIds.length > Object.keys(checkboxesByPaymentId).length) {
      const partialNewState: {
        [key: number]: boolean;
      } = {};

      paymentIds.forEach(id => (partialNewState[id] = false));

      setCheckboxesByPaymentId(prev => ({
        ...partialNewState,
        ...prev,
      }));
    }
  }, [checkboxesByPaymentId, orderDetails?.payments]);

  useEffect(() => {
    fetchMarketplaceOrderData();
  }, [fetchMarketplaceOrderData]);

  const handleCheckboxToggle = useCallback((paymentId: number) => {
    setCheckboxesByPaymentId(prev => ({
      ...prev,
      [paymentId]: !prev[paymentId],
    }));
  }, []);

  const areAllContractsChecked = useMemo(() => {
    const values = Object.values(checkboxesByPaymentId);

    if (!values.length) return false;

    return !values.some(isChecked => !isChecked);
  }, [checkboxesByPaymentId]);

  const handleSignContracts = useCallback(async () => {
    setLoadingSubmit(true);
    try {
      const serviceClassesIds = orderDetails?.payments.map(
        detail => detail.serviceClassId,
      );
      let response = null;
      if (serviceClassesIds?.length) {
        response = await getServiceClassFull(serviceClassesIds);
        setServiceClassFull(response);
      }
      if (!response.length) {
        await OrderService.signContracts(orderId);
        modalSuccessRef.current?.openDialog();
      }
    } catch (error) {
      console.error(error);
      modalErrorRef.current?.closeDialog();
    } finally {
      setLoadingSubmit(false);
    }
  }, [orderId, orderDetails]);

  const handleGoToPayments = () => {
    modalSuccessRef.current?.closeDialog();
    history.replace(`/client-marketplace/orders/${orderId}/payments`);
  };

  const handleServiceClassFull = useCallback(async () => {
    const serviceClassesIds = orderDetails?.payments.map(
      detail => detail.serviceClassId,
    );
    if (serviceClassesIds?.length) {
      const response = await getServiceClassFull(serviceClassesIds);
      setServiceClassFull(response);
    }
  }, [orderDetails]);

  useEffect(() => {
    handleServiceClassFull();
  }, [handleServiceClassFull]);

  return {
    loading,
    handleCheckboxToggle,
    handleGoToPayments,
    checkboxesByPaymentId,
    areAllContractsChecked,
    modalSuccessRef,
    modalErrorRef,
    handleSignContracts,
    loadingSubmit,
    orderDetails,
    hasServiceClassFull: !!serviceClassFull?.length,
    serviceClassFull,
  };
};
