import { CondOperator } from '@nestjsx/crud-request';
import { CustomDialogRef } from 'components/CustomDialog/types';
import { useAuth } from 'contexts/auth';
import { useAlert } from 'hooks/Alert';
import { useCart } from 'hooks/useCart';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import CreditCardService from 'services/creditCardService';
import OrderService from 'services/orderService';
import RegistrationService from 'services/registrationService';
import { LOAD_DATA_ERROR } from 'texts';
import { MESSAGE_TYPE } from 'hooks/Alert/types';
import { Card } from 'types/card';
import { Registration } from 'types/registration';
import { useServiceClassFull } from 'hooks/useServiceClassFull';
import { MarketplacePurchase } from './types';
import { ServiceClass } from '../MarketplaceServicesDetail/types';

export const useMarketplacePaymentCheckoutController = () => {
  const successDialogRef = useRef<CustomDialogRef>(null);
  const [creditCards, setCreditCards] = useState<Card[]>([]);
  const [isOpenCardModal, setIsOpenCardModal] = useState(false);
  const [isOpenDetailPaper, setIsOpenDetailPaper] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<{ id: number }>();
  const [isOpenSuccessPaymentModal, setIsOpenSuccessPaymentModal] =
    useState(false);
  const [isOpenErrorPaymentModal, setIsOpenErrorPaymentModal] = useState(false);
  const [registration, setRegistration] = useState<Registration>(
    {} as Registration,
  );
  const [serviceClassFull, setServiceClassFull] = useState<ServiceClass[]>();
  const { getServiceClassFull } = useServiceClassFull();

  // Custom Hooks
  const {
    subscriptions,
    setSubscriptions,
    cartItems,
    handleResetCartContextData,
    totalCartValue,
    handleChangeSubscriptionData,
    actualDependent,
    isSubscriptionInputCorrect,
  } = useCart();
  const { user } = useAuth();
  const { openAlert, closeAlert, isShowAlert, alertType, alertMessage } =
    useAlert();

  const history = useHistory();

  // States
  const [loading, setLoading] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);

  // Callbacks
  const handleGotoContracts = useCallback(() => {
    history.push('/client-marketplace/contracts');
  }, [history]);

  const handleDispatchAlert = useCallback(() => {
    openAlert({
      message: LOAD_DATA_ERROR,
      type: MESSAGE_TYPE.ERROR,
    });
    setTimeout(() => {
      closeAlert();
    }, 2000);
  }, [closeAlert, openAlert]);

  const loadRegistrations = useCallback(
    async (dependentId: number) => {
      try {
        setLoading(true);
        const response = await RegistrationService.filterRegistrations({
          filter: [
            {
              field: 'dependentId',
              operator: CondOperator.EQUALS,
              value: dependentId,
            },
          ],
        });

        setRegistration(response.data.data[0]);
      } catch (error) {
        openAlert({
          message: LOAD_DATA_ERROR,
          type: MESSAGE_TYPE.ERROR,
        });
        handleDispatchAlert();
      } finally {
        setLoading(false);
      }
    },
    [openAlert, handleDispatchAlert],
  );

  const loadCards = useCallback(async () => {
    try {
      setLoading(true);
      const response = await CreditCardService.creditCards({
        filter: [
          {
            field: 'financialGuardianId',
            value: user?.financialGuardian.id,
            operator: CondOperator.EQUALS,
          },
        ],
      });
      if (response.status === StatusCodes.OK) {
        setCreditCards(response.data.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      openAlert({
        message: LOAD_DATA_ERROR,
        type: MESSAGE_TYPE.ERROR,
      });
      handleDispatchAlert();
    } finally {
      setLoading(false);
    }
  }, [user, handleDispatchAlert, openAlert]);

  // const loadInstallmentsService = useCallback(
  //   async (ids: number[]) => {
  //     try {
  //       setloading(true);
  //       const response = await ServicesService.getInstallments(ids);

  //       setServicesInstallments(response.data);
  //     } catch (error) {
  //       openAlert({
  //         message: LOAD_DATA_ERROR,
  //         type: MESSAGE_TYPE.ERROR,
  //       });
  //     } finally {
  //       setloading(false);
  //     }
  //   },
  //   [openAlert],
  // );

  // FIXME: refatorar
  // Ao implementar os contratos aqui no front-end; esse cara
  // será responsável por criar DRAFTS de pagamento no backend
  // para só depois da assinatura do contrato serem processados.
  const handleGoToContract = useCallback(async () => {
    try {
      setLoading(true);
      setLoadingDialog(true);

      const itemsToBePurchased: MarketplacePurchase[] = cartItems.map(
        ({ payment, registrationId, selectedServiceClass }) => ({
          paymentMethodCode: payment.paymentMethodCode,
          registrationId,
          serviceClassId: selectedServiceClass.id,
          cardId: payment?.cardId,
          installments: payment.installments,
          couponName: payment?.couponName,
          serviceAccessCode: payment?.serviceAccessCode,
          serviceInviteCode: payment?.serviceInviteCode,
        }),
      );

      const serviceClassesIds = cartItems.map(
        cartItem => cartItem.selectedServiceClass.id,
      );
      const service = await getServiceClassFull(serviceClassesIds);
      setServiceClassFull(service);

      const response = await OrderService.createDraftOrder(itemsToBePurchased);

      if (response.status !== StatusCodes.CREATED) {
        throw new Error(response?.data?.message || 'Erro ao criar pedido');
      } else {
        // setIsOpenSuccessPaymentModal(true);
        // TODO: add validation for response.data
        setCurrentOrder(response.data);
        successDialogRef.current?.openDialog();
      }
    } catch (error) {
      setIsOpenErrorPaymentModal(true);
    } finally {
      setLoading(false);
      setLoadingDialog(false);
    }
  }, [cartItems]);

  const getSubscriptionService = (serviceId: number) => {
    return subscriptions.find(
      subscription => subscription.serviceId === serviceId,
    );
  };

  const goToContracts = () => {
    if (currentOrder?.id) {
      handleResetCartContextData();
      history.replace(
        `/client-marketplace/orders/${currentOrder.id}/contracts`,
      );
    }
  };

  // const getServiceInstallments = (serviceId: number) => {
  //   return servicesInstallments.find(
  //     serviceInstallment => serviceInstallment.id === serviceId,
  //   );
  // };

  // const handleChangeSubscription = (
  //   serviceId: number,
  //   data: SubscriptionsChange,
  // ) => {
  //   setSubscriptions(oldSubscriptions => {
  //     const index = oldSubscriptions.findIndex(
  //       subscription => subscription.serviceId === serviceId,
  //     );

  //     if (index >= 0) {
  //       if (data.paymentMethod) {
  //         oldSubscriptions[index].paymentMethodCode = data.paymentMethod;

  //         if (data.paymentMethod === PAYMENT_METHODS.PIX) {
  //           oldSubscriptions[index].installments = 1;
  //         }
  //       }

  //       if (data.cardId) {
  //         oldSubscriptions[index].cardId = data.cardId;
  //       }

  //       if (data.installments) {
  //         oldSubscriptions[index].installments = data.installments;
  //       }
  //     }

  //     return [...oldSubscriptions];
  //   });
  // };

  const handleCloseCardModal = () => {
    setIsOpenCardModal(false);
  };
  const handleOpenCardModal = () => {
    setIsOpenCardModal(true);
  };

  const handleChangeIsOpenDetailPaper = () => {
    setIsOpenDetailPaper(!isOpenDetailPaper);
  };

  const handleResetCartData = () => {
    handleResetCartContextData();
  };

  const handleClosePaymentModal = () => {
    if (isOpenSuccessPaymentModal) {
      setIsOpenSuccessPaymentModal(false);
    }

    if (isOpenErrorPaymentModal) {
      setIsOpenErrorPaymentModal(false);
    }
    handleResetCartData();
    history.push('/home');
  };

  useEffect(() => {
    loadCards();
  }, [loadCards]);

  useEffect(() => {
    if (actualDependent?.id) {
      loadRegistrations(actualDependent.id);
    }
  }, [loadRegistrations, actualDependent]);

  useEffect(() => {
    if (!cartItems.length) {
      history.push('/client-marketplace');
    }
  }, [cartItems, history]);

  const handleServiceClassFull = useCallback(async () => {
    const serviceClassesIds = cartItems.map(
      cartItem => cartItem.selectedServiceClass.id,
    );
    const response = await getServiceClassFull(serviceClassesIds);
    setServiceClassFull(response);
  }, [cartItems]);

  useEffect(() => {
    handleServiceClassFull();
  }, [handleServiceClassFull]);

  // useEffect(() => {
  //   if (cartItems.length) {
  //     const serviceIds = cartItems.map(cart => cart.service.id);
  //     loadInstallmentsService(serviceIds);
  //   }
  // }, [loadInstallmentsService, cartItems]);

  const shortenDayOfTheWeek = (dayOfTheWeek: string) => {
    return dayOfTheWeek.slice(0, 3).toUpperCase();
  };

  return {
    handleGotoContracts,
    loading,
    loadingDialog,
    // handleChangeSubscription,
    creditCards,
    loadCards,
    handleCloseCardModal,
    isOpenCardModal,
    handleOpenCardModal,
    isOpenDetailPaper,
    handleChangeIsOpenDetailPaper,
    registration,
    getSubscriptionService,
    subscriptions,
    setSubscriptions,
    // servicesInstallments,
    // getServiceInstallments,
    handleGoToContract,
    isShowAlert,
    alertType,
    alertMessage,
    closeAlert,
    isOpenSuccessPaymentModal,
    isOpenErrorPaymentModal,
    handleClosePaymentModal,
    shortenDayOfTheWeek,
    totalCartValue,
    cartItems,
    handleChangeSubscriptionData,
    actualDependent,
    isSubscriptionInputCorrect,
    successDialogRef,
    goToContracts,
    hasServiceClassFull: !!serviceClassFull?.length,
    serviceClassFull,
  };
};
