import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAlert } from 'hooks/Alert';
import { MESSAGE_TYPE } from 'hooks/Alert/types';
import { useCart } from 'hooks/useCart';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useServiceClassFull } from 'hooks/useServiceClassFull';
import { useStyles } from './styles';
import { ServiceClass } from '../MarketplaceServicesDetail/types';

export const useMarketplaceCartController = () => {
  // Custom Hooks
  const history = useHistory();
  const { cartItems, totalCartValue } = useCart();
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const { openAlert, isShowAlert, alertMessage, alertType, closeAlert } =
    useAlert();
  const [serviceClassFull, setServiceClassFull] = useState<ServiceClass[]>();
  const { getServiceClassFull } = useServiceClassFull();

  // Styles
  const styles = useStyles();

  const handleGoToPaymentCheckout = () => {
    if (cartItems.length === 0) {
      openAlert({
        message: 'O carrinho está vazio.',
        type: MESSAGE_TYPE.ERROR,
      });

      return;
    }

    history.push('/client-marketplace/payment-checkout');
  };

  const handleServiceClassFull = useCallback(async () => {
    const serviceClassesIds = cartItems.map(
      cartItem => cartItem.selectedServiceClass.id,
    );
    const response = await getServiceClassFull(serviceClassesIds);
    setServiceClassFull(response);
  }, [cartItems]);

  useEffect(() => {
    handleServiceClassFull();
  }, [handleServiceClassFull]);

  return {
    styles,
    cartItems,
    totalCartValue,
    handleGoToPaymentCheckout,
    isMobile,
    isShowAlert,
    closeAlert,
    alertMessage,
    alertType,
    hasServiceClassFull: !!serviceClassFull?.length,
    serviceClassFull,
  };
};
