import { CondOperator } from '@nestjsx/crud-request';
import { ServiceClass } from 'pages/Client/MarketplaceServicesDetail/types';
import ServiceClassesService from 'services/serviceClassesService';

export const useServiceClassFull = () => {
  const getServiceClassFull = async (serviceClassesIds: number[]) => {
    if (serviceClassesIds.length) {
      const { data: response } = await ServiceClassesService.getServiceClasses({
        filter: {
          field: 'id',
          operator: CondOperator.IN,
          value: serviceClassesIds,
        },
        join: [['service']],
      });
      if (response.data) {
        const service = response.data.filter(
          (item: ServiceClass) => item.spotsFilled >= item.spotsNumber,
        );
        return service;
      }
    }
  };

  return {
    getServiceClassFull,
  };
};
