import CustomDialog from 'components/CustomDialog';
import CustomLoadingDialog from 'components/CustomLoadindDialog';
import { FooterActions } from 'components/FooterActions';
import Scaffold from 'components/Scaffold';
import React from 'react';
import { ContractBox } from './components/ContractBox';
import { useMarketplaceContractsController } from './controller';
import { ServiceClassFullBannerInfo } from '../MarketplaceServicesDetail/components/ServiceClassFullBannerInfo';

export const MarketPlaceContracts: React.FC = () => {
  const {
    loading,
    handleCheckboxToggle,
    handleGoToPayments,
    checkboxesByPaymentId,
    areAllContractsChecked,
    modalErrorRef,
    modalSuccessRef,
    handleSignContracts,
    loadingSubmit,
    orderDetails,
    hasServiceClassFull,
    serviceClassFull,
  } = useMarketplaceContractsController();

  return (
    <>
      {serviceClassFull?.length ? (
        <ServiceClassFullBannerInfo serviceClasses={serviceClassFull} />
      ) : null}
      <Scaffold
        loading={loading}
        rawTitle="Contratação de serviços"
        rawSubtitle={
          <>
            Assinatura de <b>contrato</b>
          </>
        }
      >
        {orderDetails?.payments?.length
          ? orderDetails.payments.map(payment => (
              <ContractBox
                key={payment.id}
                payment={payment}
                isChecked={checkboxesByPaymentId[payment.id]}
                handleCheckboxToggle={handleCheckboxToggle}
              />
            ))
          : null}

        <FooterActions
          confirmTextButton="Assinar e fazer pagamento"
          disabled={!areAllContractsChecked || hasServiceClassFull}
          loading={loadingSubmit}
          onConfirm={handleSignContracts}
        />
        <CustomLoadingDialog loading={false} />
        <CustomDialog
          ref={modalSuccessRef}
          rawTitle={
            <>
              Contrato(s) <b>assinado(s) com sucesso!</b>
            </>
          }
          rawSubtitle="Siga para a tela de finalização da contratação e acompanhe os detalhes do(s) pagamento(s)."
          primaryButton={{
            text: 'Finalizar contratação',
            onClick: handleGoToPayments,
            textVariant: 'bold',
          }}
          icon={{
            name: 'checkCircle',
          }}
        />
        <CustomDialog
          ref={modalErrorRef}
          rawTitle="Tivemos um problema com a contratação."
          rawSubtitle={
            <>
              <b>Entre em contato</b> com a administração da escola para
              resolver a situação.
            </>
          }
          primaryButton={{
            text: 'Voltar ao painel principal',
            onClick: modalErrorRef.current?.closeDialog,
            textVariant: 'bold',
          }}
        />
      </Scaffold>
    </>
  );
};
