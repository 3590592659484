import { Grid, makeStyles, styled, Theme } from '@material-ui/core';
import { mediumGray, white, black, purple, red, yellow } from 'styles/theme';
import { StyledGridProps } from '../types';

export const useStyles = makeStyles({
  dialogText: {
    padding: '0px 10px',
    fontSize: 25,
    marginBottom: 15,
    textAlign: 'center',
  },
  container: {
    width: '100%',
  },
  textIpunt: {
    width: '207px',
    margin: '30px auto',
  },
  conflictMessage: {
    color: mediumGray,
  },
  circularProgressColor: {
    color: mediumGray,
  },
  circularProgressMargin: { marginTop: 25 },
  serviceCodeGridText: {
    color: black,
    fontWeight: 'bold',
  },
  inviteCodeGridText: {
    color: white,
    fontWeight: 'bold',
  },
  icon: {
    marginRight: '0px',
  },
  bannerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  link: {
    color: 'white',
    fontFamily: 'Open Sans',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

export const StretchedContainer = styled('div')(({ theme }) => ({
  width: 'calc(100% + 110px)',
  marginLeft: '-45px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '0px',
    marginRight: '-42px',
  },
}));

export const StyledCoverGrid = styled(Grid)<Theme, StyledGridProps>(
  ({ variant, theme, mini }) => {
    const coverVariants = {
      background: purple,
      color: white,
      fontWeight: 700,
    };

    if (variant === 'warning') {
      coverVariants.background = red;
    }

    if (variant === 'secondary') {
      coverVariants.background = yellow;
      coverVariants.color = black;
    }

    const coverBaseProps = {
      padding: '15px',
      paddingLeft: '69px',
      display: 'flex',
      justifyContent: 'flex-start',
      height: 'auto',
    };

    if (mini) {
      coverBaseProps.padding = '0px';
      coverBaseProps.paddingLeft = '0px';
      coverBaseProps.justifyContent = 'center';
      coverBaseProps.height = '40px';
    }

    return {
      ...coverVariants,
      ...coverBaseProps,
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '25px',
      },

      '& .text-banner': {
        color: coverVariants.color,
        fontWeight: 700,
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
      },

      '& .info-icon-tooltip': {
        color: coverVariants.color,
        fontSize: '20px',
      },
    };
  },
);
