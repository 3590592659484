import { Grid, Link, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useCart } from 'hooks/useCart';
import { ServiceClass } from '../types';
import { StyledCoverGrid } from './styles';
import { useStyles } from './styles';

interface IServiceClassFullBannerInfo {
  serviceClasses: ServiceClass[];
}

export const ServiceClassFullBannerInfo = ({
  serviceClasses,
}: IServiceClassFullBannerInfo) => {
  const styles = useStyles();
  const history = useHistory();
  const { cartItems, handleRemoveServiceFromCart } = useCart();

  const serviceClassFullText = serviceClasses.map(
    serviceClass => `${serviceClass.service?.name} - ${serviceClass.name}`,
  );

  const pluralText = `As turmas ${serviceClassFullText.join(', ')} esgotaram`;
  const singularText = `A turma ${serviceClassFullText.join(', ')} esgotou`;

  const text = `${
    serviceClasses.length > 1 ? pluralText : singularText
  } enquanto você finalizava a contratação. Para continuar, certifique que o carrinho não tem a turma esgotada e `;
  const link = 'selecione outra que esteja disponível no sistema.';

  const handleRemoveServiceClassFull = () => {
    for (const item of cartItems) {
      const serviceClassFull = serviceClasses.find(
        serviceClass => serviceClass.serviceId === item.service.id,
      );
      if (serviceClassFull) {
        handleRemoveServiceFromCart(item.service.id, item.registrationId);
      }
    }
  };

  const goToMarketplace = () => {
    handleRemoveServiceClassFull();
    history.push('/client-marketplace');
  };

  return (
    <StyledCoverGrid variant="warning">
      <Grid item className={styles.bannerContainer}>
        <Typography className="text-banner">
          {text}{' '}
          <Link onClick={goToMarketplace} className={styles.link}>
            {link}{' '}
          </Link>
        </Typography>
      </Grid>
    </StyledCoverGrid>
  );
};
